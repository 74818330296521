import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/metals-mining/*" />
    <RAC path="/zh-cn/metals-mining/*" />
    <RAC path="/zh-tw/metals-mining/*" />
    <RAC path="/pl/metals-mining/*" />
    <RAC path="/ru/metals-mining/*" />
    <RAC path="/it/metals-mining/*" />
    <RAC path="/de/metals-mining/*" />
    <RAC path="/es/metals-mining/*" />
    <RAC path="/fr/metals-mining/*" />
    <RAC path="/ar/metals-mining/*" />
    <RAC path="/id/metals-mining/*" />
    <RAC path="/pt/metals-mining/*" />
    <RAC path="/ko/metals-mining/*" />
    <DynamicNotFoundPage default />
  </Router>
)
